import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterTransactionType,
  reportTransactionTypeOptionsSelector,
  reportFilterTransactionTypeSelector,
} from '../../../state';
import { TransactionType } from '../../../types';
import { SelectFlexSales } from '../../selects';

const TransactionTypeFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loaded, loading, data } = useSelector(reportTransactionTypeOptionsSelector);
  const value = useSelector(reportFilterTransactionTypeSelector);
  const disabled = !loaded;
  const options = data || [];

  const handleChange = useCallback(
    (value: TransactionType[]) => dispatch(changeFilterTransactionType(value)),
    [dispatch]
  );

  return (
    <SelectFlexSales
      label={t('table.columns.transaction_type')}
      options={options}
      getOptionLabel="id"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      loading={loading}
      multiple
    />
  );
};

export default TransactionTypeFilter;
