import { format, parseJSON } from 'date-fns';
import { PreferencesState } from '../state';
import {
  ClosingDashboardViewMode,
  ClosingStatus,
  ClosingViewMode,
  DailySalesData,
  MasterData,
  MissingCauses,
  OperationType,
  TransactionType,
  ReprocessParameters,
  PendingInterfacesParameters,
} from '../types';
import { FindMonthClosingResponse } from './SalesApi';

const USER_PREFERENCES_KEY = 'preferences';
const MASTER_DATE_KEY = 'master-data';
const DASHBOARD_CLOSING_STATUS_KEY = 'dashboard-closing-status';
const DASHBOARD_MONTH_CLOSING_KEY = 'dashboard-month-closing';
const COLLECTIONS_DASHBOARD_MONTHLY_SALES_KEY = 'dashboard-monthly-sales';
const COLLECTIONS_DASHBOARD_DAILY_SALES_KEY = 'dashboard-daily-sales';
const DASHBOARD_DATE_KEY = 'dashboard-date';
const REPROCESS_PARAMETERS_KEY = 'reprocess-parameters';
const OPERATION_TYPES_KEY = 'operation-types-parameters';
const TRANSACTION_TYPE_KEY = 'transaction-type-parameters';
const MISSING_CLOSING_SALES_CAUSE_TYPES_KEY = 'missing_closing_sales_cause_types';
const MISSING_CLOSING_COLLECTIONS_CAUSE_TYPES_KEY = 'missing_closing_collections_cause_types';
const PENDING_INTERFACES_KEY = 'pending-interfaces-parameters';

class AppStorage {
  static setUserPreferences(preferences: PreferencesState) {
    localStorage.setItem(USER_PREFERENCES_KEY, JSON.stringify(preferences));
  }

  static getUserPreferences(): PreferencesState | null {
    return JSON.parse(localStorage.getItem(USER_PREFERENCES_KEY) || 'null');
  }

  static setMasterData(masterData: MasterData) {
    sessionStorage.setItem(MASTER_DATE_KEY, JSON.stringify(masterData));
  }

  static getMasterData() {
    return JSON.parse(sessionStorage.getItem(MASTER_DATE_KEY) || 'null');
  }

  static setOperationTypes(masterData: OperationType[]) {
    sessionStorage.setItem(OPERATION_TYPES_KEY, JSON.stringify(masterData));
  }

  static getOperationTypes(): OperationType[] | null {
    return JSON.parse(sessionStorage.getItem(OPERATION_TYPES_KEY) || 'null');
  }

  static setTransactionType(masterData: TransactionType[]) {
    sessionStorage.setItem(TRANSACTION_TYPE_KEY, JSON.stringify(masterData));
  }

  static getTransactionType(): TransactionType[] | null {
    return JSON.parse(sessionStorage.getItem(TRANSACTION_TYPE_KEY) || 'null');
  }

  static setDashboardClosingStatusData(
    dashboard: 'sales' | 'collections',
    viewMode: ClosingDashboardViewMode,
    data: ClosingStatus[],
    expiredDate: Date
  ) {
    const key = `${dashboard}-${DASHBOARD_CLOSING_STATUS_KEY}-${viewMode}`;
    sessionStorage.setItem(key, JSON.stringify(data));
    sessionStorage.setItem(DASHBOARD_DATE_KEY, JSON.stringify(expiredDate));
  }

  static getDashboardClosingStatusData(
    dashboard: 'sales' | 'collections',
    viewMode: ClosingDashboardViewMode
  ): ClosingStatus[] | null {
    const key = `${dashboard}-${DASHBOARD_CLOSING_STATUS_KEY}-${viewMode}`;
    const expiredDate = JSON.parse(sessionStorage.getItem(DASHBOARD_DATE_KEY) || 'null');
    if (expiredDate && parseJSON(expiredDate) > new Date()) {
      const data = JSON.parse(sessionStorage.getItem(key) || 'null') as ClosingStatus[];
      return data
        ? data.map(i => ({ ...i, fromDt: parseJSON(i.fromDt), toDt: parseJSON(i.toDt) }))
        : null;
    }
    return null;
  }

  static setDashboardClosedMonthsData(
    accountingDate: Date,
    data: FindMonthClosingResponse,
    expiredDate: Date
  ) {
    const key = `${DASHBOARD_MONTH_CLOSING_KEY}-${format(accountingDate, 'yyyy-MM')}`;
    sessionStorage.setItem(key, JSON.stringify(data));
    sessionStorage.setItem(DASHBOARD_DATE_KEY, JSON.stringify(expiredDate));
  }

  static getDashboardClosedMonthsData(accountingDate: Date): FindMonthClosingResponse | null {
    const expiredDate = JSON.parse(sessionStorage.getItem(DASHBOARD_DATE_KEY) || 'null');
    if (expiredDate && parseJSON(expiredDate) > new Date()) {
      const key = `${DASHBOARD_MONTH_CLOSING_KEY}-${format(accountingDate, 'yyyy-MM')}`;
      const data = JSON.parse(sessionStorage.getItem(key) || 'null') as FindMonthClosingResponse;
      return data as FindMonthClosingResponse;
    }
    return null;
  }

  static setDashboardDailySalesData(data?: DailySalesData[], expiredDate?: Date) {
    if (data && expiredDate) {
      sessionStorage.setItem(COLLECTIONS_DASHBOARD_DAILY_SALES_KEY, JSON.stringify(data));
      sessionStorage.setItem(DASHBOARD_DATE_KEY, JSON.stringify(expiredDate));
    } else {
      sessionStorage.removeItem(COLLECTIONS_DASHBOARD_DAILY_SALES_KEY);
    }
  }

  static getDashboardDailySalesData(): DailySalesData[] | null {
    const expiredDate = JSON.parse(sessionStorage.getItem(DASHBOARD_DATE_KEY) || 'null');
    if (expiredDate && parseJSON(expiredDate) > new Date()) {
      const data = JSON.parse(
        sessionStorage.getItem(COLLECTIONS_DASHBOARD_DAILY_SALES_KEY) || 'null'
      ) as DailySalesData[];
      return data as DailySalesData[];
    }
    return null;
  }

  static setReprocessParameters(reprocessParameters: ReprocessParameters) {
    sessionStorage.setItem(REPROCESS_PARAMETERS_KEY, JSON.stringify(reprocessParameters));
  }

  static setPendingInterfacesParameters(pendingInterfacesParameters: PendingInterfacesParameters) {
    sessionStorage.setItem(PENDING_INTERFACES_KEY, JSON.stringify(pendingInterfacesParameters));
  }

  static removeDashboardData() {
    sessionStorage.removeItem(COLLECTIONS_DASHBOARD_DAILY_SALES_KEY);
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes(DASHBOARD_CLOSING_STATUS_KEY)) sessionStorage.removeItem(key);
    });
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes(DASHBOARD_MONTH_CLOSING_KEY)) sessionStorage.removeItem(key);
    });
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes(COLLECTIONS_DASHBOARD_MONTHLY_SALES_KEY)) sessionStorage.removeItem(key);
    });
  }

  static getReprocessParameters() {
    return JSON.parse(sessionStorage.getItem(REPROCESS_PARAMETERS_KEY) || 'null');
  }

  static getPendingInterfacesParameters() {
    return JSON.parse(sessionStorage.getItem(PENDING_INTERFACES_KEY) || 'null');
  }

  static setCauseTypes(viewMode: ClosingViewMode, causeTypes: MissingCauses[]) {
    const key: string =
      viewMode === ClosingViewMode.SALES
        ? MISSING_CLOSING_SALES_CAUSE_TYPES_KEY
        : MISSING_CLOSING_COLLECTIONS_CAUSE_TYPES_KEY;
    sessionStorage.setItem(key, JSON.stringify(causeTypes));
  }

  static getCauseTypes(viewMode: ClosingViewMode): MissingCauses[] {
    const causesTypes: MissingCauses[] = [];
    if ([ClosingViewMode.COLLECTIONS, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
      causesTypes.push(
        ...JSON.parse(sessionStorage.getItem(MISSING_CLOSING_COLLECTIONS_CAUSE_TYPES_KEY) || '[]')
      );
    }
    if ([ClosingViewMode.SALES, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
      causesTypes.push(
        ...JSON.parse(sessionStorage.getItem(MISSING_CLOSING_SALES_CAUSE_TYPES_KEY) || '[]')
      );
    }
    return causesTypes;
  }
}

export default AppStorage;
