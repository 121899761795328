import * as yup from 'yup';
import addMonths from 'date-fns/addMonths';
import { CreateReprocessForm, ReprocessType } from '../../types';

export const maxDate = new Date();
export const minDate = addMonths(maxDate, -6);

export const typesWithDate = [
  ReprocessType.sales,
  ReprocessType.collections,
  ReprocessType.store,
  ReprocessType.economicAccounting,
];

export const typesWithDateRange = [
  ReprocessType.missingSaleType,
  ReprocessType.missingPaymentType,
  ReprocessType.noProduct,
  ReprocessType.grossIncome,
  ReprocessType.cashDifferencesArcs,
  ReprocessType.salesArcs,
  ReprocessType.missingCnpj,
  ReprocessType.missingExtraCash,
  ReprocessType.taxError,
  ReprocessType.taxesBr,
  ReprocessType.orquest,
  ReprocessType.shopping,
  ReprocessType.auditar,
];

export const typesWithStore = [
  ReprocessType.sales,
  ReprocessType.collections,
  ReprocessType.store,
  ReprocessType.economicAccounting,
  ReprocessType.missingCnpj,
  ReprocessType.missingSaleType,
];

export const typesWithFederativeEntity = [ReprocessType.taxesBr];
export const typesWithAllFailed = [ReprocessType.orquest, ReprocessType.shopping];

export const typesWithStoreMultiple = [
  ReprocessType.missingPaymentType,
  ReprocessType.cashDifferencesArcs,
  ReprocessType.salesArcs,
  ReprocessType.missingExtraCash,
  ReprocessType.taxError,
  ReprocessType.orquest,
  ReprocessType.shopping,
  ReprocessType.auditar,
];
export const typesWithIbGroupsMultiple = [ReprocessType.grossIncome];

export const getSchema = (): yup.ObjectSchema<CreateReprocessForm> =>
  yup.object().shape({
    type: yup.string().required(),
    country: yup
      .string()
      .when('allFailed', ([allFailed], schema) => (!allFailed ? schema.required() : schema)),
    store: yup
      .string()
      .when('type', ([type], schema) =>
        typesWithStore.includes(type) ? schema.required() : schema
      ),
    stores: yup
      .array()
      .when(['type', 'allFailed'], ([type, allFailed], schema) =>
        typesWithStoreMultiple.includes(type) && !allFailed ? schema.min(1) : schema
      ),
    groupIds: yup
      .array()
      .when('type', ([type], schema) =>
        typesWithIbGroupsMultiple.includes(type) ? schema.min(1) : schema
      ),
    businessDate: yup
      .date()
      .when('type', ([type], schema) =>
        type && typesWithDate.includes(type) ? schema.required().min(minDate).max(maxDate) : schema
      ),
    businessDateFrom: yup.date().when('type', ([type], schema) =>
      typesWithDateRange.includes(type)
        ? schema
            .required()
            .min(minDate)
            .when('businessDateTo', ([businessDateTo], schema) =>
              businessDateTo ? schema.max(yup.ref('businessDateTo')) : schema
            )
        : schema
    ),
    businessDateTo: yup.date().when('type', ([type], schema) =>
      typesWithDateRange.includes(type)
        ? schema
            .required()
            .max(maxDate)
            .when('businessDateFrom', ([businessDateFrom], schema) =>
              businessDateFrom ? schema.min(yup.ref('businessDateFrom')) : schema
            )
        : schema
    ),
    justification: yup.string().required().max(80),
    allFailed: yup.bool(),
    federativeEntity: yup
      .string()
      .when('type', ([type], schema) =>
        typesWithFederativeEntity.includes(type) ? schema.required() : schema
      ),
  });
