import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createPendingInterfacesRequestMap,
  executionPendingInterfacesRequestMap,
  executionPendingInterfacesResponseMap,
  findCancellationsRequestMap,
  findCancellationsResponseMap,
  findClosingRequestMap,
  findClosingResponseMap,
  findClosingStatusTotalRequestMap,
  findDiscountsRequestMap,
  findDiscountsResponseMap,
  findGCsByProductRequestMap,
  findGCsByProductResponseMap,
  findGcsByPaymentTypesRequestMap,
  findGcsByPaymentTypesResponseMap,
  findGcsRequestMap,
  findGcsResponseMap,
  findGrossSaleRequestMap,
  findHourRangeResponseMap,
  findMastersImportExecutionRequestMap,
  findMastersImportRequestMap,
  findMastersImportResponseMap,
  findMissingClosingCausesRequestMap,
  findMissingClosingCollectionsCausesResponseMap,
  findMissingClosingSalesCausesResponseMap,
  findMissingClosingTotalResponseMap,
  findOperationTypesResponseMap,
  findTransactionTypeRequestMap,
  findTransactionTypeResponseMap,
  findPaymentTypeRequestMap,
  findPaymentTypeResponseMap,
  findPendingInterfacesParametersResponseMap,
  findPendingInterfacesRequestMap,
  findPendingInterfacesResponseMap,
  findPettyCashDifferenceByPaymentTypeRequestMap,
  findPettyCashDifferenceByPaymentTypeResponseMap,
  findPettyCashDifferenceRequestMap,
  findPettyCashDifferenceResponseMap,
  findPosRequestMap,
  findPosResponseMap,
  findReliefsRequestMap,
  findReliefsResponseMap,
  findReportTenderRequestMap,
  findReportTenderResponseMap,
  findSalesByProductRequestMap,
  findSalesByProductResponseMap,
  findSalesJournalFcsResponseMap,
  findSalesJournalGcsResponseMap,
  findSalesJournalNcsResponseMap,
  findSalesJournalRequestMap,
  findSalesJournalThirdPartyResponseMap,
  findSalesRequestMap,
  findSalesResponseMap,
  findSalesTypesRequestMap,
  findSalesTypesResponseMap,
  findThirdPartySalesByItemsRequestMap,
  findThirdPartySalesByItemsResponseMap,
  findTimeBandRequestMap,
  findTimeBandResponseMap,
  getCountriesForPermissions,
  getSelectedEndDate,
  getSelectedStartDate,
  hasIncompleteRanges,
  noValidDateRanges,
} from '../../helpers';
import {
  AppStorage,
  CollectionsApi,
  ExecutionPendingInterfacesResponse,
  FindCancellationsResponse,
  FindClosingResponse,
  FindDiscountsResponse,
  FindGCsByProductResponse,
  FindGcsByPaymentTypesResponse,
  FindGcsResponse,
  FindGrossSaleResponse,
  FindMastersImportResponse,
  FindMastersTypesResponse,
  FindMissingClosingCausesResponse,
  FindOperationTypesResponse,
  FindTransactionTypeResponse,
  FindPaymentTypesResponse,
  FindPendingInterfacesParametersResponse,
  FindPendingInterfacesResponse,
  FindPettyCashDifferenceResponse,
  FindPosResponse,
  FindReportTenderResponse,
  FindSalesByProductResponse,
  FindSalesJournalResponse,
  FindSalesResponse,
  FindSalesTypesResponse,
  FindThirdPartySalesByItemsResponse,
  FindTimeBandResponse,
  SalesApi,
  SalesParametersApi,
} from '../../services';
import {
  AppPermissions,
  CancellationsFilter,
  CausesFilter,
  ClosingFilter,
  ClosingViewMode,
  CollectionsFilter,
  Country,
  ExecutionStatusPendingInterfacesFilter,
  FindClosingStatusResponse,
  GcsFilter,
  HttpRequestError,
  MasterData,
  MastersImportDetailFilter,
  MissingCauses,
  OpStructuresFilter,
  PendingInterfacesFilter,
  Permission,
  ReportFilter,
  Stores,
} from '../../types';
import {
  activeCountriesSelector,
  masterDataDataSelector,
  reportCancellationsFilterSelector,
  reportCausesFilterSelector,
  reportClosingFilterSelector,
  reportCollectionsFilterSelector,
  reportExecutePendingInterfacesFilterSelector,
  reportFilterSelector,
  reportGcsFilterSelector,
  reportMastersImportFilterSelector,
  reportMissingClosingTotalFilteredStoresSelector,
  reportOpStructuresFilterSelector,
  reportPendingInterfacesFilterSelector,
  userPermissionsDataSelector,
} from '../selectors';
import {
  executePendingInterfacesFailure,
  executePendingInterfacesParametersFailure,
  executePendingInterfacesParametersSuccess,
  executePendingInterfacesSuccess,
  findCancellationsFailure,
  findCancellationsSuccess,
  findCauseTypesFailure,
  findCauseTypesSuccess,
  findClosingCollectionsFailure,
  findClosingCollectionsSuccess,
  findClosingSalesFailure,
  findClosingSalesSuccess,
  findGCsByProductFailure,
  findGCsByProductSuccess,
  findGcsByPaymentTypesFailure,
  findGcsByPaymentTypesSuccess,
  findGcsFailure,
  findGcsSuccess,
  findHourRangeFailure,
  findHourRangeRequested,
  findHourRangeSuccess,
  findItemDiscountsFailure,
  findItemDiscountsRequested,
  findItemDiscountsSuccess,
  findMastersImportFailure,
  findMastersImportSuccess,
  findMastersTypesFailure,
  findMastersTypesSuccess,
  findMissingClosingCollectionsCausesFailure,
  findMissingClosingCollectionsCausesSuccess,
  findMissingClosingSalesCausesFailure,
  findMissingClosingSalesCausesSuccess,
  findMissingClosingTotalFailure,
  findMissingClosingTotalRequested,
  findMissingClosingTotalSuccess,
  findOperationTypesFailure,
  findOperationTypesSuccess,
  findTransactionTypeFailure,
  findTransactionTypeSuccess,
  findPaymentTypeFailure,
  findPaymentTypeRequested,
  findPaymentTypeSuccess,
  findPendingInterfacesSalesFailure,
  findPendingInterfacesSalesSuccess,
  findPettyCashDifferenceByPaymentTypeFailure,
  findPettyCashDifferenceByPaymentTypeSuccess,
  findPettyCashDifferenceFailure,
  findPettyCashDifferenceSuccess,
  findPosFailure,
  findPosRequested,
  findPosSuccess,
  findReliefsFailure,
  findReliefsSuccess,
  findReportTenderFailure,
  findReportTenderSuccess,
  findSaleDiscountsFailure,
  findSaleDiscountsRequested,
  findSaleDiscountsSuccess,
  findSalesByProductFailure,
  findSalesByProductSuccess,
  findSalesFailure,
  findSalesJournalFcsFailure,
  findSalesJournalFcsSuccess,
  findSalesJournalGcsFailure,
  findSalesJournalGcsSuccess,
  findSalesJournalNcsFailure,
  findSalesJournalNcsSuccess,
  findSalesJournalThirdPartyFailure,
  findSalesJournalThirdPartySuccess,
  findSalesSuccess,
  findSalesTypesFailure,
  findSalesTypesRequested,
  findSalesTypesSuccess,
  findThirdPartySalesByItemsFailure,
  findThirdPartySalesByItemsSuccess,
  findTimeBandFailure,
  findTimeBandRequested,
  findTimeBandSuccess,
  mastersImportExecutionFailure,
  mastersImportExecutionSuccess,
  pendingInterfacesCreateFailure,
  pendingInterfacesCreateSuccess,
} from './actionCreators';
import {
  ActionType,
  FindClosingCollectionsRequestedAction,
  FindClosingSalesRequestedAction,
  FindGCsByProductRequestedAction,
  FindGcsByPaymentTypesRequestedAction,
  FindGcsRequestedAction,
  FindMissingClosingTotalRequestedAction,
  FindPendingInterfacesSalesRequestedAction,
  FindPettyCashDifferenceByPaymentTypeRequestedAction,
  FindPettyCashDifferenceRequestedAction,
  FindReportTenderRequestedAction,
  FindSalesByProductRequestedAction,
  FindSalesRequestedAction,
  FindThirdPartySalesByItemsRequestedAction,
  MastersImportExecutionRequestedAction,
  PendingInterfacesCreateRequestedAction,
} from './types';

import { enqueueNotification } from '..';
import {
  FindPettyCashDifferenceByPaymentTypeResponse,
  FindReliefsResponse,
} from '../../services/CollectionsApi';

function* notifyOnedriveSuccess() {
  yield put(
    enqueueNotification({
      key: 'sendOnedriveSuccess',
      message: t => t('shared.messages.sendToOnedrive'),
      variant: 'success',
    })
  );
}

function* findPos() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  if (filters.stores.length !== 1 || noValidDateRanges(filters) || hasIncompleteRanges(filters))
    return;
  yield put(findPosRequested());
  try {
    const { data }: AxiosResponse<FindPosResponse> = yield call(
      SalesParametersApi.findPos,
      findPosRequestMap(
        filters.countryCode,
        filters.stores[0],
        getSelectedStartDate(filters) as Date,
        getSelectedEndDate(filters) as Date
      )
    );
    yield put(findPosSuccess(findPosResponseMap(data)));
  } catch (error) {
    yield put(findPosFailure(error as HttpRequestError));
  }
}

function* findSalesTypes() {
  const { countryCode }: ReportFilter = yield select(reportFilterSelector);
  if (!countryCode) return;

  yield put(findSalesTypesRequested());
  try {
    const { data }: AxiosResponse<FindSalesTypesResponse> = yield call(
      SalesParametersApi.findSalesTypes,
      findSalesTypesRequestMap(countryCode)
    );
    yield put(findSalesTypesSuccess(findSalesTypesResponseMap(data)));
  } catch (error) {
    yield put(findSalesTypesFailure(error as HttpRequestError));
  }
}

function* findCauseTypes() {
  const { viewMode }: ClosingFilter = yield select(reportClosingFilterSelector);

  let causeTypes: MissingCauses[] = AppStorage.getCauseTypes(viewMode);

  try {
    if (causeTypes.length === 0) {
      if ([ClosingViewMode.COLLECTIONS, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
        const { data }: AxiosResponse<MissingCauses[]> = yield call(CollectionsApi.findCauseTypes);
        causeTypes.push(...data);
        AppStorage.setCauseTypes(ClosingViewMode.COLLECTIONS, data);
      }
      if ([ClosingViewMode.SALES, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
        const { data }: AxiosResponse<MissingCauses[]> = yield call(SalesApi.findCauseTypes);
        causeTypes.push(...data);
        AppStorage.setCauseTypes(ClosingViewMode.SALES, data);
      }
    }

    causeTypes = [...new Set(causeTypes)] as MissingCauses[];
    yield put(findCauseTypesSuccess(causeTypes));
  } catch (error) {
    yield put(findCauseTypesFailure(error as HttpRequestError));
  }
}

function* findPaymentType() {
  const { countries }: MasterData = yield select(masterDataDataSelector);
  const { countryCode }: ReportFilter = yield select(reportFilterSelector);
  const countryId = countries.find(i => i.countryCode === countryCode)?.countryId;

  if (!countryId) return;
  yield put(findPaymentTypeRequested());
  try {
    const { data }: AxiosResponse<FindPaymentTypesResponse> = yield call(
      SalesParametersApi.findPaymentTypes,
      findPaymentTypeRequestMap(countryId)
    );
    yield put(findPaymentTypeSuccess(findPaymentTypeResponseMap(data)));
  } catch (error) {
    yield put(findPaymentTypeFailure(error as HttpRequestError));
  }
}

function* findTimeBand() {
  const { countryCode }: ReportFilter = yield select(reportFilterSelector);
  if (!countryCode) return;

  yield put(findHourRangeRequested());
  yield put(findTimeBandRequested());

  try {
    const { data }: AxiosResponse<FindTimeBandResponse> = yield call(
      SalesParametersApi.findTimeBand,
      findTimeBandRequestMap(countryCode)
    );
    yield put(findHourRangeSuccess(findHourRangeResponseMap(data)));
    yield put(findTimeBandSuccess(findTimeBandResponseMap(data)));
  } catch (error) {
    yield put(findHourRangeFailure(error as HttpRequestError));
    yield put(findTimeBandFailure(error as HttpRequestError));
  }
}

function* findSales(action: FindSalesRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const stores: Stores[] = yield select(reportMissingClosingTotalFilteredStoresSelector);
  const opStructuresFilters: OpStructuresFilter = yield select(reportOpStructuresFilterSelector);
  try {
    yield put(
      findMissingClosingTotalRequested(
        getSelectedStartDate(filters) as Date,
        getSelectedEndDate(filters) as Date,
        filters.countryCode,
        filters.stores.length > 0 ? filters.stores : stores
      )
    );
    const { data, status }: AxiosResponse<FindSalesResponse> = yield call(
      SalesApi.findSales,
      findSalesRequestMap(filters, opStructuresFilters, action.sentToOnedrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findSalesSuccess([]));
    } else {
      yield put(findSalesSuccess(findSalesResponseMap(data)));
    }
  } catch (error) {
    yield put(findSalesFailure(error as HttpRequestError));
  }
}

function* findTenderReport(action: FindReportTenderRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const stores: Stores[] = yield select(reportMissingClosingTotalFilteredStoresSelector);
  const opStructuresFilters: OpStructuresFilter = yield select(reportOpStructuresFilterSelector);
  try {
    yield put(
      findMissingClosingTotalRequested(
        getSelectedStartDate(filters) as Date,
        getSelectedEndDate(filters) as Date,
        filters.countryCode,
        filters.stores.length > 0 ? filters.stores : stores
      )
    );
    const { data, status }: AxiosResponse<FindReportTenderResponse> = yield call(
      SalesApi.findReportTender,
      findReportTenderRequestMap(filters, opStructuresFilters, action.sendToOnedrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findReportTenderSuccess([]));
    } else {
      yield put(findReportTenderSuccess(findReportTenderResponseMap(data)));
    }
  } catch (error) {
    yield put(findReportTenderFailure(error as HttpRequestError));
  }
}

function* findGcs(action: FindGcsRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const gcsFilters: GcsFilter = yield select(reportGcsFilterSelector);

  try {
    const { data, status }: AxiosResponse<FindGcsResponse> = yield call(
      SalesApi.findGcs,
      findGcsRequestMap(filters, gcsFilters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findGcsSuccess([]));
    } else {
      yield put(findGcsSuccess(findGcsResponseMap(data)));
    }
  } catch (error) {
    yield put(findGcsFailure(error as HttpRequestError));
  }
}

function* findGcsByPaymentTypes(action: FindGcsByPaymentTypesRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const gcsFilters: GcsFilter = yield select(reportGcsFilterSelector);

  try {
    const { data, status }: AxiosResponse<FindGcsByPaymentTypesResponse> = yield call(
      SalesApi.findGcsByPaymentTypes,
      findGcsByPaymentTypesRequestMap(filters, gcsFilters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findGcsByPaymentTypesSuccess([]));
    } else {
      yield put(findGcsByPaymentTypesSuccess(findGcsByPaymentTypesResponseMap(data)));
    }
  } catch (error) {
    yield put(findGcsByPaymentTypesFailure(error as HttpRequestError));
  }
}

function* findClosingSales({ startDate, endDate }: FindClosingSalesRequestedAction) {
  try {
    const filters: ClosingFilter = yield select(reportClosingFilterSelector);
    const { data: activeCountries } = yield select(activeCountriesSelector);
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const countriesForPermissions = getCountriesForPermissions(
      [AppPermissions.MISSING_CLOSING_DETAIL],
      permissions,
      activeCountries
    );
    const countries =
      filters.countries.length > 0
        ? filters.countries.map(i => i.countryCode)
        : countriesForPermissions.map(i => i.countryCode);
    const start = startDate || (filters.startBusinessDate.accept as Date);
    const end = endDate || (filters.endBusinessDate.accept as Date);

    const { data }: AxiosResponse<FindClosingResponse> = yield call(
      SalesApi.findClosing,
      findClosingRequestMap(
        countries,
        filters.stores,
        start,
        end,
        filters.causeTypes,
        filters.federativeEntities,
        filters.franchiseTypes
      )
    );

    yield put(findClosingSalesSuccess(findClosingResponseMap(data, 'sales'), start, end));
  } catch (error) {
    yield put(findClosingSalesFailure(error as HttpRequestError));
  }
}

function* findClosingCollections({ startDate, endDate }: FindClosingCollectionsRequestedAction) {
  try {
    const filters: ClosingFilter = yield select(reportClosingFilterSelector);
    const { data: activeCountries } = yield select(activeCountriesSelector);
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const countriesForPermissions = getCountriesForPermissions(
      [AppPermissions.MISSING_CLOSING_DETAIL],
      permissions,
      activeCountries
    );
    const countries =
      filters.countries.length > 0
        ? filters.countries.map(i => i.countryCode)
        : countriesForPermissions.map(i => i.countryCode);
    const start = startDate || (filters.startBusinessDate.accept as Date);
    const end = endDate || (filters.endBusinessDate.accept as Date);

    const { data }: AxiosResponse<FindClosingResponse> = yield call(
      CollectionsApi.findClosing,
      findClosingRequestMap(
        countries,
        filters.stores,
        start,
        end,
        filters.causeTypes,
        filters.federativeEntities,
        filters.franchiseTypes
      )
    );

    yield put(
      findClosingCollectionsSuccess(findClosingResponseMap(data, 'collections'), start, end)
    );
  } catch (error) {
    yield put(findClosingCollectionsFailure(error as HttpRequestError));
  }
}

function* findMissingClosingSalesCauses() {
  const causesFilters: CausesFilter = yield select(reportCausesFilterSelector);
  try {
    const { data }: AxiosResponse<FindMissingClosingCausesResponse> = yield call(
      SalesApi.findMissingClosingCauses,
      findMissingClosingCausesRequestMap(causesFilters)
    );
    yield put(findMissingClosingSalesCausesSuccess(findMissingClosingSalesCausesResponseMap(data)));
  } catch (error) {
    yield put(findMissingClosingSalesCausesFailure(error as HttpRequestError));
  }
}

function* findMissingClosingCollectionsCauses() {
  const causesFilters: CausesFilter = yield select(reportCausesFilterSelector);
  try {
    const { data }: AxiosResponse<FindMissingClosingCausesResponse> = yield call(
      CollectionsApi.findCollectionsMissingClosingCauses,
      findMissingClosingCausesRequestMap(causesFilters)
    );
    yield put(
      findMissingClosingCollectionsCausesSuccess(
        findMissingClosingCollectionsCausesResponseMap(data)
      )
    );
  } catch (error) {
    yield put(findMissingClosingCollectionsCausesFailure(error as HttpRequestError));
  }
}

function* findMissingClosingTotal(actions: FindMissingClosingTotalRequestedAction) {
  const { country, stores, dateFrom, dateTo } = actions;
  try {
    const { data }: AxiosResponse<FindClosingStatusResponse> = yield call(
      SalesApi.findClosingSalesStatus,
      findClosingStatusTotalRequestMap(dateFrom, dateTo, country, stores)
    );
    yield put(findMissingClosingTotalSuccess(findMissingClosingTotalResponseMap(data)));
  } catch (error) {
    yield put(findMissingClosingTotalFailure(error as HttpRequestError));
  }
}

function* findReliefs() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const collectionsFilters: CollectionsFilter = yield select(reportCollectionsFilterSelector);
  const { countries }: MasterData = yield select(masterDataDataSelector);
  const { countryId } = countries.find(i => i.countryCode === filters.countryCode) as Country;
  try {
    const { data }: AxiosResponse<FindPaymentTypesResponse> = yield call(
      SalesParametersApi.findPaymentTypes,
      findPaymentTypeRequestMap(countryId)
    );
    const localPaymentTypeId = data.find(i => i.payment_type === 'ML')?.payment_type_id;
    const [grossSaleResponse, ReliefsResponse]: [
      AxiosResponse<FindGrossSaleResponse>,
      AxiosResponse<FindReliefsResponse>
    ] = yield all([
      call(
        SalesApi.findGrossSale,
        findGrossSaleRequestMap(filters, collectionsFilters, localPaymentTypeId)
      ),
      call(CollectionsApi.findReliefs, findReliefsRequestMap(filters, collectionsFilters)),
    ]);
    yield put(
      findReliefsSuccess(
        findReliefsResponseMap(collectionsFilters, grossSaleResponse.data, ReliefsResponse.data)
      )
    );
  } catch (error) {
    yield put(findReliefsFailure(error as HttpRequestError));
  }
}

function* findCancellations() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const cancellationsFilters: CancellationsFilter = yield select(reportCancellationsFilterSelector);
  try {
    const { data }: AxiosResponse<FindCancellationsResponse> = yield call(
      SalesApi.findCancellations,
      findCancellationsRequestMap(filters, cancellationsFilters)
    );
    yield put(findCancellationsSuccess(findCancellationsResponseMap(data)));
  } catch (error) {
    yield put(findCancellationsFailure(error as HttpRequestError));
  }
}

function* findOperationTypes() {
  try {
    let operationTypes = AppStorage.getOperationTypes();
    if (!operationTypes) {
      const { data }: AxiosResponse<FindOperationTypesResponse> = yield call(
        SalesParametersApi.findOperationTypes
      );
      operationTypes = findOperationTypesResponseMap(data);
    }
    yield put(findOperationTypesSuccess(operationTypes));
  } catch (error) {
    yield put(findOperationTypesFailure(error as HttpRequestError));
  }
}

function* findTransactionType() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    let transactionType = AppStorage.getTransactionType();
    if (!transactionType) {
      const { data }: AxiosResponse<FindTransactionTypeResponse> = yield call(
        SalesParametersApi.findTransactionType,
        findTransactionTypeRequestMap(filters)
      );
      transactionType = findTransactionTypeResponseMap(data);
    }
    yield put(findTransactionTypeSuccess(transactionType));
  } catch (error) {
    yield put(findTransactionTypeFailure(error as HttpRequestError));
  }
}

function* findSalesByProduct(action: FindSalesByProductRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const stores: Stores[] = yield select(reportMissingClosingTotalFilteredStoresSelector);
  const opStructuresFilters: OpStructuresFilter = yield select(reportOpStructuresFilterSelector);
  try {
    yield put(
      findMissingClosingTotalRequested(
        getSelectedStartDate(filters) as Date,
        getSelectedEndDate(filters) as Date,
        filters.countryCode,
        filters.stores.length > 0 ? filters.stores : stores
      )
    );
    const { data, status }: AxiosResponse<FindSalesByProductResponse> = yield call(
      SalesApi.findSalesByProduct,
      findSalesByProductRequestMap(filters, opStructuresFilters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findSalesByProductSuccess([]));
    } else {
      yield put(findSalesByProductSuccess(findSalesByProductResponseMap(data)));
    }
  } catch (error) {
    yield put(findSalesByProductFailure(error as HttpRequestError));
  }
}

function* findGCsByProduct(action: FindGCsByProductRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const gcsFilters: GcsFilter = yield select(reportGcsFilterSelector);

  try {
    const { data, status }: AxiosResponse<FindGCsByProductResponse> = yield call(
      SalesApi.findGCsByProduct,
      findGCsByProductRequestMap(filters, gcsFilters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findGCsByProductSuccess([]));
    } else {
      yield put(findGCsByProductSuccess(findGCsByProductResponseMap(data)));
    }
  } catch (error) {
    yield put(findGCsByProductFailure(error as HttpRequestError));
  }
}

function* findThirdPartySalesByItems(action: FindThirdPartySalesByItemsRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    const { data, status }: AxiosResponse<FindThirdPartySalesByItemsResponse> = yield call(
      SalesApi.findThirdPartySalesByItems,
      findThirdPartySalesByItemsRequestMap(filters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findThirdPartySalesByItemsSuccess([]));
    } else {
      yield put(findThirdPartySalesByItemsSuccess(findThirdPartySalesByItemsResponseMap(data)));
    }
  } catch (error) {
    yield put(findThirdPartySalesByItemsFailure(error as HttpRequestError));
  }
}

function* findPettyCashDifference(action: FindPettyCashDifferenceRequestedAction) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const collectionsFilters: CollectionsFilter = yield select(reportCollectionsFilterSelector);
  try {
    const { data, status }: AxiosResponse<FindPettyCashDifferenceResponse> = yield call(
      CollectionsApi.findPettyCashDifference,
      findPettyCashDifferenceRequestMap(filters, collectionsFilters, action.sendToOndrive)
    );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findPettyCashDifferenceSuccess([]));
    } else {
      yield put(findPettyCashDifferenceSuccess(findPettyCashDifferenceResponseMap(data)));
    }
  } catch (error) {
    yield put(findPettyCashDifferenceFailure(error as HttpRequestError));
  }
}

function* findPettyCashDifferenceByPaymentType(
  action: FindPettyCashDifferenceByPaymentTypeRequestedAction
) {
  const filters: ReportFilter = yield select(reportFilterSelector);
  const collectionsFilters: CollectionsFilter = yield select(reportCollectionsFilterSelector);
  try {
    const { data, status }: AxiosResponse<FindPettyCashDifferenceByPaymentTypeResponse> =
      yield call(
        CollectionsApi.findPettyCashDifferenceByPaymentType,
        findPettyCashDifferenceByPaymentTypeRequestMap(
          filters,
          collectionsFilters,
          action.sendToOndrive
        )
      );
    if (status === 202) {
      yield notifyOnedriveSuccess();
      yield put(findPettyCashDifferenceByPaymentTypeSuccess([]));
    } else {
      yield put(
        findPettyCashDifferenceByPaymentTypeSuccess(
          findPettyCashDifferenceByPaymentTypeResponseMap(data)
        )
      );
    }
  } catch (error) {
    yield put(findPettyCashDifferenceByPaymentTypeFailure(error as HttpRequestError));
  }
}

function* findSalesJournalGcs() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    const { data }: AxiosResponse<FindSalesJournalResponse> = yield call(
      SalesApi.findSalesJournal,
      findSalesJournalRequestMap(filters, 'gcs')
    );
    yield put(findSalesJournalGcsSuccess(findSalesJournalGcsResponseMap(data)));
  } catch (error) {
    yield put(findSalesJournalGcsFailure(error as HttpRequestError));
  }
}

function* findSalesJournalNcs() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    const { data }: AxiosResponse<FindSalesJournalResponse> = yield call(
      SalesApi.findSalesJournal,
      findSalesJournalRequestMap(filters, 'ncs')
    );
    yield put(findSalesJournalNcsSuccess(findSalesJournalNcsResponseMap(data)));
  } catch (error) {
    yield put(findSalesJournalNcsFailure(error as HttpRequestError));
  }
}

function* findSalesJournalFcs() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    const { data }: AxiosResponse<FindSalesJournalResponse> = yield call(
      SalesApi.findSalesJournal,
      findSalesJournalRequestMap(filters, 'fcs')
    );
    yield put(findSalesJournalFcsSuccess(findSalesJournalFcsResponseMap(data)));
  } catch (error) {
    yield put(findSalesJournalFcsFailure(error as HttpRequestError));
  }
}

function* findSalesJournalThirdParty() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  try {
    const { data }: AxiosResponse<FindSalesJournalResponse> = yield call(
      SalesApi.findSalesJournal,
      findSalesJournalRequestMap(filters, 'thirdparty')
    );
    yield put(findSalesJournalThirdPartySuccess(findSalesJournalThirdPartyResponseMap(data)));
  } catch (error) {
    yield put(findSalesJournalThirdPartyFailure(error as HttpRequestError));
  }
}

function* findSaleDiscounts() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  if (!filters.countryCode || noValidDateRanges(filters) || hasIncompleteRanges(filters)) return;
  yield put(findSaleDiscountsRequested());
  try {
    const { data }: AxiosResponse<FindDiscountsResponse> = yield call(
      SalesParametersApi.findSaleDiscounts,
      findDiscountsRequestMap(filters)
    );
    yield put(findSaleDiscountsSuccess(findDiscountsResponseMap(data)));
  } catch (error) {
    yield put(findSaleDiscountsFailure(error as HttpRequestError));
  }
}

function* findItemDiscounts() {
  const filters: ReportFilter = yield select(reportFilterSelector);
  if (!filters.countryCode || noValidDateRanges(filters) || hasIncompleteRanges(filters)) return;
  yield put(findItemDiscountsRequested());
  try {
    const { data }: AxiosResponse<FindDiscountsResponse> = yield call(
      SalesParametersApi.findItemDiscounts,
      findDiscountsRequestMap(filters)
    );
    yield put(findItemDiscountsSuccess(findDiscountsResponseMap(data)));
  } catch (error) {
    yield put(findItemDiscountsFailure(error as HttpRequestError));
  }
}

function* findMastersImport() {
  const filters: MastersImportDetailFilter = yield select(reportMastersImportFilterSelector);
  const { countries }: MasterData = yield select(masterDataDataSelector);
  try {
    const { data }: AxiosResponse<FindMastersImportResponse> = yield call(
      SalesApi.findMastersImport,
      findMastersImportRequestMap(filters)
    );
    yield put(findMastersImportSuccess(findMastersImportResponseMap(data, countries)));
  } catch (error) {
    yield put(findMastersImportFailure(error as HttpRequestError));
  }
}

function* findMastersTypes() {
  try {
    const { data }: AxiosResponse<FindMastersTypesResponse> = yield call(SalesApi.findMastersTypes);
    yield put(findMastersTypesSuccess(data));
  } catch (error) {
    yield put(findMastersTypesFailure(error as HttpRequestError));
  }
}

function* mastersImportExecution({ payload }: MastersImportExecutionRequestedAction) {
  try {
    yield call(SalesApi.mastersImportExecution, findMastersImportExecutionRequestMap(payload));
    yield put(mastersImportExecutionSuccess());
  } catch (error) {
    yield put(mastersImportExecutionFailure(error as HttpRequestError));
  }
}

function* mastersImportExecutionSuccessNotification() {
  yield put(
    enqueueNotification({
      key: 'mastersImportExecutionSucces',
      message: t => t('masters-import.execution.message-success'),
      variant: 'success',
    })
  );
}

function* findPendingInterfaceSales({
  startDate,
  endDate,
}: FindPendingInterfacesSalesRequestedAction) {
  try {
    const filters: PendingInterfacesFilter = yield select(reportPendingInterfacesFilterSelector);
    const start = startDate || (filters.startBusinessDate.accept as Date);
    const end = endDate || (filters.endBusinessDate.accept as Date);

    const { data }: AxiosResponse<FindPendingInterfacesResponse> = yield call(
      SalesApi.findPendingInterface,
      findPendingInterfacesRequestMap(filters)
    );

    yield put(
      findPendingInterfacesSalesSuccess(findPendingInterfacesResponseMap(data), start, end)
    );
  } catch (error) {
    yield put(findPendingInterfacesSalesFailure(error as HttpRequestError));
  }
}

function* createPendingInterfaces({ payload }: PendingInterfacesCreateRequestedAction) {
  try {
    yield call(SalesApi.createPendingInterfaces, createPendingInterfacesRequestMap(payload));
    yield put(pendingInterfacesCreateSuccess());
  } catch (error) {
    yield put(pendingInterfacesCreateFailure(error as HttpRequestError));
  }
}

function* executefindPendingInterface() {
  try {
    const filters: ExecutionStatusPendingInterfacesFilter = yield select(
      reportExecutePendingInterfacesFilterSelector
    );

    const { data }: AxiosResponse<ExecutionPendingInterfacesResponse> = yield call(
      SalesApi.executionsPendingInterfaces,
      executionPendingInterfacesRequestMap(filters)
    );

    yield put(executePendingInterfacesSuccess(executionPendingInterfacesResponseMap(data)));
  } catch (error) {
    yield put(executePendingInterfacesFailure(error as HttpRequestError));
  }
}

function* getParameters() {
  let pendinInterfacesParameters = AppStorage.getPendingInterfacesParameters();

  try {
    if (!pendinInterfacesParameters) {
      const { data }: AxiosResponse<FindPendingInterfacesParametersResponse> = yield call(
        SalesApi.findPendingInterfacesParameters
      );
      pendinInterfacesParameters = findPendingInterfacesParametersResponseMap(data);
      AppStorage.setPendingInterfacesParameters(pendinInterfacesParameters);
    }
    yield put(executePendingInterfacesParametersSuccess(pendinInterfacesParameters));
  } catch (error) {
    yield put(executePendingInterfacesParametersFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(
    [
      ActionType.changeFiltersStore,
      ActionType.changeFilterStartAccountingDate,
      ActionType.changeFilterEndAccountingDate,
      ActionType.changeFilterStartBusinessDate,
      ActionType.changeFilterEndBusinessDate,
      ActionType.changeFilterStartOperationDate,
      ActionType.changeFilterEndOperationDate,
    ],
    findPos
  ),
  takeLatest(ActionType.changeFiltersCountry, findSalesTypes),
  takeLatest(ActionType.changeFiltersCountry, findPaymentType),
  takeLatest(ActionType.changeFiltersCountry, findTimeBand),
  takeLatest(ActionType.changeFiltersCountry, findTransactionType),
  takeLatest(ActionType.findSalesRequested, findSales),
  takeLatest(ActionType.findGcsRequested, findGcs),
  takeLatest(ActionType.findGcsByPaymentTypesRequested, findGcsByPaymentTypes),
  takeLatest(ActionType.findClosingSalesRequested, findClosingSales),
  takeLatest(ActionType.findClosingCollectionsRequested, findClosingCollections),
  takeLatest(ActionType.findMissingClosingSalesCausesRequested, findMissingClosingSalesCauses),
  takeLatest(
    ActionType.findMissingClosingCollectionsCausesRequested,
    findMissingClosingCollectionsCauses
  ),
  takeLatest(ActionType.findMissingClosingTotalRequested, findMissingClosingTotal),
  takeLatest(ActionType.findReportTenderRequested, findTenderReport),
  takeLatest(ActionType.findReliefsRequested, findReliefs),
  takeLatest(ActionType.findCancellationsRequested, findCancellations),
  takeLatest(ActionType.findOperationTypesRequested, findOperationTypes),
  takeLatest(ActionType.findSalesByProductRequested, findSalesByProduct),
  takeLatest(ActionType.findGCsByProductRequested, findGCsByProduct),
  takeLatest(ActionType.findThirdPartySalesByItemsRequested, findThirdPartySalesByItems),
  takeLatest(ActionType.findPettyCashDifferenceRequested, findPettyCashDifference),
  takeLatest(
    ActionType.findPettyCashDifferenceByPaymentTypeRequested,
    findPettyCashDifferenceByPaymentType
  ),
  takeLatest(ActionType.findSalesJournalRequested, findSalesJournalGcs),
  takeLatest(ActionType.findSalesJournalRequested, findSalesJournalNcs),
  takeLatest(ActionType.findSalesJournalRequested, findSalesJournalFcs),
  takeLatest(ActionType.findSalesJournalRequested, findSalesJournalThirdParty),
  takeLatest(
    [
      ActionType.changeFiltersCountry,
      ActionType.changeFiltersStore,
      ActionType.changeFilterStartAccountingDate,
      ActionType.changeFilterEndAccountingDate,
      ActionType.changeFilterStartBusinessDate,
      ActionType.changeFilterEndBusinessDate,
      ActionType.changeFilterStartOperationDate,
      ActionType.changeFilterEndOperationDate,
    ],
    findSaleDiscounts
  ),
  takeLatest(
    [
      ActionType.changeFiltersCountry,
      ActionType.changeFiltersStore,
      ActionType.changeFilterStartAccountingDate,
      ActionType.changeFilterEndAccountingDate,
      ActionType.changeFilterStartBusinessDate,
      ActionType.changeFilterEndBusinessDate,
      ActionType.changeFilterStartOperationDate,
      ActionType.changeFilterEndOperationDate,
    ],
    findItemDiscounts
  ),
  takeLatest(ActionType.findMastersImportRequested, findMastersImport),
  takeLatest(ActionType.findMastersTypesRequested, findMastersTypes),
  takeLatest(ActionType.mastersImportExecutionRequested, mastersImportExecution),
  takeLatest(ActionType.mastersImportExecutionSuccess, mastersImportExecutionSuccessNotification),
  takeLatest(ActionType.findCauseTypesRequested, findCauseTypes),
  takeLatest(ActionType.findPendingInterfacesSalesRequested, findPendingInterfaceSales),
  takeLatest(ActionType.pendingInterfacesCreateRequested, createPendingInterfaces),
  takeLatest(ActionType.executePendingInterfacesRequested, executefindPendingInterface),
  takeLatest(ActionType.executePendingInterfacesParametersRequested, getParameters),
];
