import { salesParametersAxios } from '../config/axiosInstances';
import { AppParameters } from '../types';

export type GetPosTypesResponse = {
  segment_id: string;
  segment_description: string;
}[];

export type GetActiveCountriesResponse = {
  division_id: string;
  division: string;
  country: string;
  abr_3: string;
  active: boolean;
  country_id: string;
  name: string;
}[];

export interface FindPosRequest {
  countryAcronym: string;
  storeAcronym: string;
  accountingDateRange: string;
}

export type FindPosResponse = string[];

export interface FindSalesTypesRequest {
  countryAcronym: string;
}

export type FindSalesTypesResponse = {
  country_id: string;
  sale_type_id: string;
  sale_type_description: string;
}[];

export interface FindTimeBandRequest {
  countryAcronym: string;
}

export type FindTimeBandResponse = {
  band: string;
  order: string;
  day_part_id: string;
  country: string;
  wh_day_part_id: string;
}[];
export type FindOperationTypesResponse = {
  operation_type: number;
  operation_type_name: string;
}[];

export interface FindTransactionTypeRequest {
  countryAcronym: string;
}

export type FindTransactionTypeResponse = [];

export interface FindPaymentTypesRequest {
  countryId: string;
}

export type FindPaymentTypesResponse = {
  country_id: string;
  tender_id: string;
  payment_type: string;
  payment_type_id: number;
  short_name: string;
}[];
export interface FindDiscountsRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  accountingDateRange?: string;
  operationDateRange?: string;
}

export type FindDiscountsResponse = {
  id_discount: number;
  description: string;
  transaction_type: number;
}[];

export type GetParametersResponse = {
  parameters: AppParameters;
  storeExtraInfo: Record<
    string,
    { store_id: string; name: string; base_tore_id: string | undefined }[]
  >;
};

export default class {
  static async getPosTypes() {
    return salesParametersAxios.get<GetPosTypesResponse>('/sales_segments');
  }

  static async getActiveCountries() {
    return salesParametersAxios.get<GetActiveCountriesResponse>('/active_countries');
  }

  static async findPos(request: FindPosRequest) {
    return salesParametersAxios.get<FindPosResponse>(`/pos`, { params: request });
  }

  static async findSalesTypes(request: FindSalesTypesRequest) {
    return salesParametersAxios.get<FindSalesTypesResponse>(`/sales_types`, { params: request });
  }

  static async findTimeBand(request: FindTimeBandRequest) {
    return salesParametersAxios.get<FindTimeBandResponse>(`/time_band`, {
      params: request,
    });
  }

  static async findOperationTypes() {
    return salesParametersAxios.get<FindOperationTypesResponse>(`/operation-types`);
  }

  static async findTransactionType(request: FindTransactionTypeRequest) {
    return salesParametersAxios.get<FindTransactionTypeResponse>(`/transaction-type`, {
      params: request,
    });
  }

  static async findPaymentTypes(request: FindPaymentTypesRequest) {
    return salesParametersAxios.get<FindPaymentTypesResponse>(`/payment-types`, {
      params: request,
    });
  }

  static async findSaleDiscounts(request: FindDiscountsRequest) {
    return salesParametersAxios.get<FindDiscountsResponse>(`/sale_discounts`, {
      params: request,
    });
  }

  static async findItemDiscounts(request: FindDiscountsRequest) {
    return salesParametersAxios.get<FindDiscountsResponse>(`/item_discounts`, {
      params: request,
    });
  }

  static async getParameters() {
    return salesParametersAxios.get<GetParametersResponse>('/parameters');
  }

  static async getFederativeEntities() {
    return salesParametersAxios.get<Record<string, string[]>>('/federative_entities');
  }
}
